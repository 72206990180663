<template>
    <div class="app_home">
        <el-container>
            <el-header height="56px">
                <v-head :userInfo="userInfo"></v-head>
            </el-header>
            
            <el-main>
                <keep-alive :include="cachePage">
                    <router-view></router-view>
                </keep-alive>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import vHead from "./vHead.vue";
export default {
    name: 'appHome',
    components: {
        vHead,
    },
    data() {
        return {
            userInfo: this.LocalStorage.get('userInfo') || null
        }
    },
	watch: {
	},
    computed: {
        // 缓存页面
        cachePage () {
            return this.$store.state.cachePage;
        },
    },
    created() {
    },
    mounted() {},
    methods: {

    }
}
</script>

<style lang="less" scoped>
.app_home{
    height: 100%;
    width: 100%;
    .el-container {
        height: 100%;
        .el-header {
            padding: 0;
        }
        .el-main {
            padding: 0;
            box-sizing: border-box;
        }
    }
}
</style>