import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/login/index.vue'),
        meta: {
            title: '登录'
        }
    },
    {
      path: '/programme',
        name: 'programme',
        component: () => import('../views/programme/index.vue'),
        meta: {
            requireAuth: true, // 校验登陆状态 false == 不校验
            title: '方案列表'
        }
    },
    {
      path: '/createProgramme',
        name: 'createProgramme',
        component: () => import('../views/createProgramme/index.vue'),
        meta: {
            requireAuth: true, // 校验登陆状态 false == 不校验
            title: '创建方案'
        }
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  // 登录状态
  const isRegister = !!localStorage.getItem('userInfo');

  // 登陆页并且登陆状态存在，直接进入方案列表页
  if (to.path == '/') {
    if (isRegister) {
      next('/programme');
    }
    next()
    return
  }

  // 需要校验登录状态并且有登录信息
  // to.query.enterOmp == 1 omp嵌入的页面不需要登陆信息
  if ((to.meta.requireAuth && isRegister) || to.query.enterOmp == 1) {
    next();
    return
  } else {
    next('/');
  }
  
});

export default router
