
const axios = require('axios').default;
const CancelToken = axios.CancelToken;
const cancelRequest = CancelToken.source(); // 取消请求

import { Message } from "element-ui";

import { LocalStorage, isEmpty } from './index';
import { tryHideFullScreenLoading, showFullScreenLoading } from './pageLoading';

const isDev = process.env.NODE_ENV === 'development' ? true : false;

let URL_PREFIX = location.origin;
  



if (location.hostname == 'localhost' || 
    location.protocol == "file:" || 
    /^192\.168\.\d+\.\d+/.test(location.hostname) || location.hostname == 'http://127.0.0.1/') { // 本地环境
    URL_PREFIX = '//programmetest.e-edusky.com';
}


let operation = URL_PREFIX + '/operation/';
let api = URL_PREFIX + '/api/';
URL_PREFIX = URL_PREFIX + '/plan/'

const $ = axios.create({
    baseURL: '',
    timeout: 15000,
});

window.$axios = $;

// 请求拦截器
$.interceptors.request.use((config) => {
    const userInfo = LocalStorage.get('userInfo');
    if (config.showLoading) {
        showFullScreenLoading(config);
    }
    config.headers = {
        'Content-Type': 'application/json',
    };
    config.params = {
        requestId: Math.random()
    }
    if (userInfo) {
        config.headers.jeecgId = userInfo.id;
    }
    return config;
}, error => Promise.reject(error));

// 响应拦截器
$.interceptors.response.use((response) => {
    if (response.config.showLoading) {
        tryHideFullScreenLoading();
    }
    
    if (response.data.httpCode) {

        if (response.data.httpCode != '200' || (response.data.code !== '000000' && response.data.code !== 'Success')) {
            Message.error(response.data.message ? response.data.message : '请求错误,请重试');
        }
        
    } else if (response.data.status) {
        
        if (response.data.status != '200') {
            Message.error(response.data.message ? response.data.message : '请求错误,请重试');
        }
    } else {
        if (response.data.code !== '000000' && response.data.code !== 'Success') {
            Message.error(response.data.message ? response.data.message : '请求错误,请重试');
        }
    }
    return response.data;
}, (error) => {
    tryHideFullScreenLoading();
    Message.error('服务器响应错误');
    return Promise.reject(error);
});
var oauth2Type = '';
export default {
    request: (interfaceConfig, data = {}, config) => {
        if (!config) {
            config = { showLoading: false };
        }
        /**
         * @todo 异常时需进一步提示
         */
        if (interfaceConfig.method === undefined || interfaceConfig.path === undefined) {
            return;
        }
        if (interfaceConfig.method === 'post') {
            data['oauth2Type'] = oauth2Type;
            return $.post(interfaceConfig.path, data, config);
        }
        let pathUrl = '';

        if (isEmpty(data)) {
            pathUrl = `${interfaceConfig.path}?oauth2Type=${oauth2Type}`;
        } else {
            data[oauth2Type] = oauth2Type
            pathUrl = `${interfaceConfig.path}?${encodeSearchParams(data)}`;
            // pathUrl = `${url}?${data}&oauth2Type=${oauth2Type}`;
        }
        return $.get(pathUrl, config);
    },
    post: (url, data = {}, config) => {
        
        data['oauth2Type'] = oauth2Type;
        if (!config) {
            config = { showLoading: false };
        }
        return $.post(url, data, config);
    },
    get: (url, data = {}, config) => {
        if (!config) {
            config = { showLoading: false };
        }
        let pathUrl = '';
        if (isEmpty(data)) {
            pathUrl = `${url}?oauth2Type=${oauth2Type}`;
        } else {
            // pathUrl = `${url}?${encodeSearchParams(data)}`;
            pathUrl = `${url}?${data}&oauth2Type=${oauth2Type}`;
        }
        return $.get(pathUrl, config);
    },
    operation,
    api,
    cancelRequest
};
