
//主要用来定义方法，改变state里面的数据
let mutations = {
    setCachePage: (state, newVal) => {
        // 设置缓存页面
        state.cachePage = newVal;
    },
    
    setMetaData: (state, newVal) => {
        // BPMN出入参数信息
        state.metaData = newVal;
    },
};
export default mutations;
