<template>
	<div id="app">
		
		<keep-alive :include="cachePage" v-if="isLoginPath">
			<router-view></router-view>
		</keep-alive>
		<home v-else></home>
	</div>
</template>

<script>
import home from '@/components/main/home.vue'
import $http from '@/utils/http'
export default {
    name: 'app',
	components: {home},
    data() {
        return {
			isLoginPath: false
        }
    },
	computed: {
		cachePage () {
			return this.$store.state.cachePage;
		},
  	},
	watch: {
		$route (to, from) {
			$http.cancelRequest.cancel('Operation canceled by the user.');
			if (to.fullPath == '/') {
				this.isLoginPath = true
			} else {
				this.isLoginPath = false
			}
		},
	},
}
</script>

<style lang="less">
html, body,#app {
	height: 100%;
	width: 100%;
	margin: 0;
}
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	
}
.el-select-dropdown {
    .el-scrollbar {
        .el-scrollbar__wrap, .el-select-dropdown__wrap  {
            overflow-x: hidden;
        } 
    }
} 
::-webkit-scrollbar-thumb {
	/*滚动条里面可以拖动的那部分（位置5）*/
	background: #e8d4b5;
	border-radius: 3px;
}
::-webkit-scrollbar-track-piece {
	/*内层轨道，滚动条中间部分（位置4）*/
	background: #f3e6d3;
	border-radius: 3px;
	width: 6%;
}
::-webkit-scrollbar {
	display: inline-block !important;
	width: 6px;
}
::-webkit-scrollbar-track {
	/*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
	display: none;
}
</style>
