

const S4 = () =>
  (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
const guid = () =>
  `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;

/**
 * 睡眠时间
 * 结合ES6的 async await generator
 * @param {number} time
 */
const sleep = (time) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });

// 对get求情进行编码
const encodeSearchParams = (obj) => {
  const params = [];
  Object.keys(obj).forEach((key) => {
    let value = obj[key];
    // 如果值为undefined我们将其置空
    if (typeof value === "undefined") {
      value = "";
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURIComponent(value)].join("="));
  });
  return params.join("&");
};

// 判断是否为空
const isEmpty = (value) => {
  switch (typeof value) {
    case "undefined":
      return true;
    case "string":
      if (value.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length === 0)
        return true;
      break;
    case "boolean":
      if (!value) return true;
      break;
    case "number":
      if (value === 0 || isNaN(value)) return true;
      break;
    case "object":
      if (value === null || value.length === 0) return true;
      for (const key in value) {
        return false;
      }
      return true;
    default:
  }
  return false;
};

/**
 *
 * @param {判断是否是json字符串} val
 */

const isJSON = (str) => {
  if (isObject(str)) return true;

  if (!isString(str)) return false;

  str = str.replace(/\s/g, "").replace(/\n|\r/, "");

  if (/^\{(.*?)\}$/.test(str)) return /"(.*?)":(.*?)/g.test(str);

  if (/^\[(.*?)\]$/.test(str)) {
    return str
      .replace(/^\[/, "")
      .replace(/\]$/, "")
      .replace(/},{/g, "}\n{")
      .split(/\n/)
      .map(function (s) {
        return isJSON(s);
      })
      .reduce(function (prev, curr) {
        return !!curr;
      });
  }

  return false;
};

function isString (x) {
  return Object.prototype.toString.call(x) === '[object String]';
}

function isObject (obj) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

function getSubjects (subjects, gradeCode, val) {
  let arr = [];
  subjectList.forEach(item => {
    if (Number(gradeCode) < 31 && item.subjectCode == 9) {
      item.subjectName = "道德与法治";
    } else if (item.subjectCode == 9) {
      item.subjectName = "政治";
    }
    if (subjects.indexOf(Number(item.subjectCode)) > -1) {
      arr.push({
        subjectCode: item.subjectCode,
        subjectName: item.subjectName,
        code: item.subjectCode,
        name: item.subjectName,
        keyStr: "subjectCode" // 标记是哪一个筛选项
      });
    }
  });
  if (val) {
    arr.unshift({
      code: "",
      name: "全部",
      keyStr: "subjectCode"
    });
  }
  return arr;
}

// 选择学年弹框学科数据

export const gradeDialogSubjects = (gradeCode, val) => {
  let arr = [];
  gradeCode = gradeCode + "";
  switch (gradeCode) {
    case "11":
      arr = getSubjects([1, 2, 3], gradeCode);
      break;
    case "12":
      arr = getSubjects([1, 2, 3], gradeCode);
      break;
    case "13":
      arr = getSubjects([1, 2, 3], gradeCode);
      break;
    case "14":
      arr = getSubjects([1, 2, 3], gradeCode);
      break;
    case "15":
      arr = getSubjects([1, 2, 3], gradeCode);
      break;
    case "16":
      arr = getSubjects([1, 2, 3], gradeCode);
      break;
    case "21":
      arr = getSubjects([1, 2, 3, 6, 8], gradeCode);
      break;
    case "22":
      arr = getSubjects([1, 2, 3, 4, 6, 8], gradeCode);
      break;
    case "23":
      arr = getSubjects([1, 2, 3, 4, 6, 8], gradeCode);
      break;
    case "24":
      arr = getSubjects([1, 2, 3, 4, 5, 6, 7, 8, 9], gradeCode);
      break;
    case "31":
      arr = getSubjects([1, 2, 3, 4, 5, 6], gradeCode);
      break;
    case "32":
      arr = getSubjects([1, 2, 3, 4, 5, 6, 7, 8, 9], gradeCode);
      break;
    case "33":
      arr = getSubjects([1, 2, 3, 4, 5, 6, 7, 8, 9], gradeCode);
      break;
    default:
      arr = getSubjects([1, 2, 3, 4, 5, 6, 7, 8, 9], gradeCode);
  }

  return arr;
};

// 处理学科属于哪个学段

const gradeToStage = (val) => {
  if (!val) return;
  let tebleIndex = 0;
  if (
    val == "11" ||
    val == "12" ||
    val == "13" ||
    val == "14" ||
    val == "15" ||
    val == "16"
  ) {
    tebleIndex = 0;
  } else if (val == "21" || val == "22") {
    tebleIndex = 1;
  } else if (val == "23" || val == "24") {
    tebleIndex = 2;
  } else if (val == "31" || val == "32") {
    tebleIndex = 3;
  } else if (val == "33") {
    tebleIndex = 4;
  }

  return tebleIndex;
};

// diagnosisStatus诊断状态 0默认已发送 1已提交 2已出报告 3报告异常 4 未作答
const getDiagnosisStatus = (val) => {
  if (val == 0) {
    return "开始测评";
  } else if (val == 1) {
    return "已提交";
  } else if (val == 2) {
    return "查看报告";
  } else if (val == 3) {
    return "报告异常";
  } else if (val == 4) {
    return "未作答";
  }
};

// LocalStorage与SessionStorage封装
function StorageSet (storageString) {
  const storage = window[storageString];

  if (!window[storageString]) {
    // alert("浏览器支持"+storageString);
    return false;
  }

  const set = (key, value) => {
    storage.setItem(key, JSON.stringify(value));
  };

  const get = (key) => {
    let mydata = storage.getItem(key);

    if (!mydata) {
      return false;
    }
    try {
      mydata = JSON.parse(mydata);
    }
    catch (err) {
      return mydata;
    }

    return mydata;
  };

  const remove = (key) => {
    storage.removeItem(key);
  };

  const clear = () => {
    storage.clear();
  };

  return {
    set,
    get,
    remove,
    clear,
  };
}

/*图片*/
var strToUrl = (str) => {
  if (!str || typeof str != "string") return "";
  str = str.toString();
  //如果已经是html格式  直接返回
  if (/<img\b[^>]*>|<audio\b[^>]*>/.test(str)) return str;
  let string = "";
  
  string = str.replace(/[\r\n]{1,}/g, "");
  string = string.replace(/[\r\n]*$/g, "");
  string = string.replace(/[\r\n]/g, "<br>");

  return string.replace(
    /(http:\/\/|https:\/\/).*?\.(mp3|jpg|jpeg|png|gif)/gi,
    function (w) {
      if (/mp3$/i.test(w)) {
        return "<audio src=" + w + " controls />";
      } else {
        return (
          "<img style=max-width:100%;vertical-align:middle;  src=" + w + " />"
        );
      }
    }
  );
};
/*图片解析*/
var strUrlChange = (str) => {
  if (!str || typeof str != "string") return "";
  str = str.toString();
  //如果已经是html格式  直接返回
  if (str.indexOf("<img") > -1) return str;

  let string = "";
  
  string = str.replace(/[\r\n]{1,}/g, "");
  string = string.replace(/&nbsp;/g, " ");
  string = string.replace(/[\r\n]*$/g, "");
  string = string.replace(/[\r\n]/g, "<br>");

  return string.replace(
    /(http:\/\/|https:\/\/).*?\.(mp3|jpg|jpeg|png|gif)/gi,
    function (w) {
      if (/mp3$/i.test(w)) {
        return '<audio src="' + w + '" controls/>';
      } else {
        return (
          '<img class="media-middle" style="vertical-align: middle;max-width: 100%;" src="' +
          w +
          '"/>'
        );
      }
    }
  );
};
const LocalStorage = StorageSet("localStorage");
const SessionStorage = StorageSet("sessionStorage");

const GrandList = {
  "11": "一年级",
  "12": "二年级",
  "13": "三年级",
  "14": "四年级",
  "15": "五年级",
  "16": "六年级",
  "21": "七年级",
  "22": "八年级",
  "23": "九年级",
  "24": "中考",
  "31": "高一",
  "32": "高二",
  "33": "高考",
};
const gredeList = [
  {
    gradeName: "一年级",
    gradeCode: "11",
  },
  {
    gradeName: "二年级",
    gradeCode: "12",
  },
  {
    gradeName: "三年级",
    gradeCode: "13",
  },
  {
    gradeName: "四年级",
    gradeCode: "14",
  },
  {
    gradeName: "五年级",
    gradeCode: "15",
  },
  {
    gradeName: "六年级",
    gradeCode: "16",
  },
  {
    gradeName: "七年级",
    gradeCode: "21",
  },
  {
    gradeName: "八年级",
    gradeCode: "22",
  },
  {
    gradeName: "九年级",
    gradeCode: "23",
  },
  {
    gradeName: "中考",
    gradeCode: "24",
  },
  {
    gradeName: "高一",
    gradeCode: "31",
  },
  {
    gradeName: "高二",
    gradeCode: "32",
  },
  {
    gradeName: "高考",
    gradeCode: "33",
  },
];

const commonSubject = {
  "1": "语文",
  "2": "数学",
  "4": "物理",
  "3": "英语",
  "5": "化学",
  "6": "生物",
  "7": "历史",
  "8": "地理",
  "9": "政治",
};

const subjectAbbreviation = {
  "1": "语",
  "2": "数",
  "4": "物",
  "3": "英",
  "5": "化",
  "6": "生",
  "7": "历",
  "8": "地",
  "9": "政",
};

const allCommonSubject = {
  '0': '全部',
  '1': '语文',
  '2': '数学',
  '4': '物理',
  '3': '英语',
  '5': '化学',
  '6': '生物',
  '7': '历史',
  '8': '地理',
  '9': '政治',
  '10': '科学',
  '11': '信息技术',
  '12': '品德与生活',
  '13': '品德与社会',
  '14': '思想品德',
  '15': '心理学',
  '16': '习惯趣谈'
}

// 暂时只用数学物理
const searchSubjectList = [
  // {
  //   text: "语文",
  //   code: "1",
  // },
  {
    text: "数学",
    code: "2",
  },
  // {
  //   text: "英语",
  //   code: "3",
  // },
  {
    text: "物理",
    code: "4",
  },
  // {
  //   text: "化学",
  //   code: "5",
  // },
  // {
  //   text: "生物",
  //   code: "6",
  // },
  // {
  //   text: "历史",
  //   code: "7",
  // },
  // {
  //   text: "地理",
  //   code: "8",
  // },
  // {
  //   text: "政治",
  //   code: "9",
  // },
];

// 判断token是否过期
const isTokenExpired = () => {
  // 获取本地时间
  const nowTime = new Date().getTime() / 1000;
  // 获取过期时间
  const ExpireTime = LocalStorage.get("accessTokenExpireTime");
  if (!ExpireTime) {
    return false;
  }
  // 校验本地时间
  const difftime = 10;
  // 如果 < 10分钟，则说明即将过期
  return ExpireTime - nowTime < difftime * 60;
};

// 禁止用户修改字体大小
const banUserReviseFontSize = () => {
  if (
    typeof WeixinJSBridge == "object" &&
    typeof WeixinJSBridge.invoke == "function"
  ) {
    handleFontSize();
  } else {
    if (document.addEventListener) {
      document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
    } else if (document.attachEvent) {
      document.attachEvent("WeixinJSBridgeReady", handleFontSize);
      document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
    }
  }
  function handleFontSize () {
    WeixinJSBridge.invoke("setFontSizeCallback", {
      fontSize: 0,
    });
    WeixinJSBridge.on("menu:setfont", function () {
      WeixinJSBridge.invoke("setFontSizeCallback", {
        fontSize: 0,
      });
    });
  }
};

// 将秒转化为时分秒
const formatSeconds = (value, val) => {
  let result = parseInt(value);
  let h =
    Math.floor(result / 3600) < 10
      ? "0" + Math.floor(result / 3600)
      : Math.floor(result / 3600);
  let m =
    Math.floor((result / 60) % 60) < 10
      ? "0" + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60);
  let s =
    Math.floor(result % 60) < 10
      ? "0" + Math.floor(result % 60)
      : Math.floor(result % 60);
  // 　　result = `${h}:${m}:${s}`
  if (Number(h) <= 0 && Number(m) > 0) {
    result = `${m}分${s}秒`;
  } else if (Number(h) <= 0 && Number(m) <= 0) {
    result = `${s}秒`;
  } else {
    result = `${h}时${m}分${s}秒`;
  }
  return result;
};



/**
 *
 * @param {数字转中文数字} value
 */
const ArabelToCN = (i) => {
  var arrNum = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
    "十",
    "佰",
  ];
  if (i <= 10) {
    return arrNum[i];
  } else if (i < 20) {
    return arrNum[10] + (i % 10 > 0 ? arrNum[parseInt(i % 10)] : "");
  } else if (i < 100) {
    return (
      arrNum[parseInt(i / 10)] +
      arrNum[10] +
      (i % 10 > 0 ? arrNum[parseInt(i % 10)] : "")
    );
  }
  return i;
};

/**
 * 题型，题型code转文字
 */
const questionType = (val) => {
  let str = "单选题";
  const num = val + "";
  switch (num) {
    case "2":
      str = "多选题";
      break;
    case "3":
      str = "判断题";
      break;
    case "4":
      str = "填空题";
      break;
    case "5":
      str = "主观题";
      break;
    case "6":
      str = "复合题";
      break;
    case "7":
      str = "自判题";
      break;
    default:
      str = "单选题";
  }

  return str;
};


// 判断是否是 android终端
const isAndroid = () => {

  const u = navigator.userAgent
  return u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
}



//写cookies
const setCookie = (name, value, exDays) => {
  var exp = new Date();
  exp.setTime(exp.getTime() + exDays * 24 * 60 * 60 * 1000);
  document.cookie = name + "=" + encodeURI(value) + ";expires=" + exp.toGMTString();
}
//读取cookies
const getCookie = (name) => {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg)) return decodeURI(arr[2]);
  else return null;
}
//删除cookies
const delCookie = (name) => {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null) document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

// 数字转中文数字
const arabicToCN = (i) => {
	var arrNum = [
		"零",
		"一",
		"二",
		"三",
		"四",
		"五",
		"六",
		"七",
		"八",
		"九",
		"十",
		"佰"
	];
	if (i <= 10) {
		return arrNum[i];
	} else if (i < 20) {
		return (
			arrNum[10] + (i % 10 > 0 ? arrNum[parseInt(i % 10)] : "")
		);
	} else if (i < 100) {
		return (
			arrNum[parseInt(i / 10)] +
			arrNum[10] +
			(i % 10 > 0 ? arrNum[parseInt(i % 10)] : "")
		);
	}
	return i;
};


/**
 * json 2 urlHash
 */
const jsonTourl = (jsons) => {
  let url = "";
  for (let i in jsons){
      if(url === ""){
          url += "?" + i + "=" + jsons[i]
      }else{
          url += "&" + i + "=" + jsons[i]
      }
  }
  return url;
}


  /**
   * copy方法
   */
  const copyTxt = (text) => {
    if(typeof document.execCommand!=="function"){
      return false;
    }
    var dom = document.createElement("textarea");
    dom.value = text;
    dom.setAttribute('style', 'display: block;width: 1px;height: 1px;');
    document.body.appendChild(dom);
    dom.select();
    var result = document.execCommand('copy');
    document.body.removeChild(dom);
    if (result) {
      return true;
    }
    if(typeof document.createRange!=="function"){
      return false;
    }
    var range = document.createRange();
    var div=document.createElement('div');
    div.innerHTML=text;
    div.setAttribute('style', 'height: 1px;fontSize: 1px;overflow: hidden;');
    document.body.appendChild(div);
    range.selectNode(div);
    const selection = window.getSelection();
    if (selection.rangeCount > 0){
      selection.removeAllRanges();
    }
    selection.addRange(range);
    document.execCommand('copy');
    return true;
  }

export {
  S4,
  guid,
  sleep,
  encodeSearchParams,
  isEmpty,
  LocalStorage,
  SessionStorage,
  isTokenExpired,
  banUserReviseFontSize,
  GrandList,
  gredeList,
  commonSubject,
  allCommonSubject,
  strToUrl,
  strUrlChange,
  formatSeconds,
  getDiagnosisStatus,
  gradeToStage,
  searchSubjectList,
  subjectAbbreviation,
  isJSON,
  ArabelToCN,
  questionType,
  isAndroid,
  getCookie,
  setCookie,
  delCookie,
  arabicToCN,
  jsonTourl,
  copyTxt,
};