import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

/*左边工具栏以及编辑节点的样式*/
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
Vue.use(ElementUI);

Vue.config.productionTip = false;

// 定义全局方法或属性
import * as vuePrototype from './utils/index';
for (let key in vuePrototype) {
  Vue.prototype[key] = vuePrototype[key];
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
