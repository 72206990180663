<template>
    <div class="vHead">
        <div class="vHead_menu">
            <el-menu
                :default-active="$route.path"
                router
                background-color="rgb(119 142 255)"
                text-color="#303133"
                active-text-color="#FFFFFF"
                unique-opened>
                
                <el-menu-item index="/programme" v-show="$route.path.indexOf('/programme') > -1">{{$route.meta.title}}</el-menu-item>
                <el-menu-item index="/createProgramme"  v-show="$route.path.indexOf('/createProgramme') > -1">{{!$route.query.edit ? '创建方案' : $route.query.edit == '2' ? '查看方案' : '编辑方案' }}</el-menu-item>
            </el-menu>
        </div>
        <div class="vHead_exit">
            <el-dropdown @command="exitHandler">
                <span class="el-dropdown-link">
                    <span>{{getName}}</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="exit" >退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>
<script>
export default {
    name: "vHead",
    props: {
        userInfo: {
            type: Object,
            default: () => {
                return null
            }
        }
    },
    computed: {
        getName() {
            if (!this.userInfo) return '';
            const subjectName = this.userInfo?.gradeCode ? this.commonSubject[this.userInfo.gradeCode] : '';
            return subjectName ? `(${subjectName})${this.userInfo.name}` : this.userInfo.name;
        }
    },
    data() {
        return {

        }
    },
    created() {},
    mounted() {},
    methods: {
        exitHandler(val) {
            if (val == 'exit') {
                localStorage.clear();
                this.$router.replace({
                    path: '/'
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.vHead {
    position: relative;
    .vHead_menu {
        .el-menu {
            display: flex;
            justify-content: center;
            .el-menu-item {
                font-size: 20px;
                &:hover, &.is-active:hover {
                    background: rgb(119 142 255) !important;
                    color: #3c3c3c !important;
                }
            }
        }
    }
    .vHead_exit {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #FFFFFF;
        .el-dropdown {
            color: #FFFFFF;
            .el-dropdown-link {
                cursor: pointer;
            }
        }
    }

}
</style>